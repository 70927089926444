import Cookies from 'js-cookie';

export default {
  name: 'cookieManager',
  transformToHuman(val, t) {
    if ([undefined, null].includes(val)) return val;
    if (t === Boolean) return (val.toLowerCase() === 'true');
    if (t === Number) return (parseInt(val, 10));
    return val;
  },
  getCookieExpiration() {
    // Return a expiration date 24h from now
    const now = new Date();
    return new Date(now.getTime() + 60 * 60000 * 24);
  },
  // cookieAsObject(cookie) {
  //   return cookie.split(/[;] */).reduce((result, pairStr) => {
  //     const arr = pairStr.split('=');
  //     const [key, value] = arr;
  //     if (arr.length === 2) {
  //       result[key] = value; // eslint-disable-line no-param-reassign
  //     }
  //     return result;
  //   }, {});
  // },
  set(name, value, expiration) {
    // eslint-disable-next-line
    if (!expiration) expiration = this.getCookieExpiration();
    // eslint-disable-next-line
    expiration = expiration.toUTCString();
    document.cookie = `${name}=${value}; expires=${expiration}; path=/; Secure; SameSite=Lax;`;
  },
  get(name, t = String) {
    const val = Cookies.get(name);
    if (!val) return null;
    return this.transformToHuman(val, t);
  },
  hasReadAllAppDocuments() {
    return this.get('has_read_all_app_documents', Boolean);
  },
  delete(name) {
    Cookies.delete(name);
  },
  deleteAll() {
    document.cookie.split(';').forEach((c) => {
      document.cookie = `${c.trim().split('=')[0]}=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
    });
    localStorage.clear();
  },
};
