import Vue from 'vue';
import VueRouter from 'vue-router';
import cookieManager from '@/store/cookieManager';

Vue.use(VueRouter);
/*
add here guards to disable accesess to routers if user is not logged in
example here https://medium.com/js-dojo/how-to-implement-route-guard-in-vue-js-9929c93a13db
*/
function requireCookie(to, from, next) {
  if (cookieManager.get('Authorization') == null) {
    next({ name: 'Login' });
  } else {
    next();
  }
}
const routes = [
  {
    path: '/',
    name: 'Start',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/update-expired-password',
    name: 'UpdateExpiredPassword',
    component: () => import('@/views/UpdateExpiredPassword.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword.vue'),
  },
  {
    path: '/check-email',
    name: 'CheckEmail',
    component: () => import('@/views/CheckEmail.vue'),
  },
  {
    path: '/forgot-password/:token/',
    name: 'ForgotPasswordConfirm',
    component: () => import('@/views/ForgotPasswordConfirm.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: () => import('@/views/UserProfile.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/control-panel',
    name: 'ControlPanel',
    component: () => import('@/views/ControlPanel.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/dashboards',
    name: 'Dashboards',
    component: () => import('@/views/Dashboards.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/studies',
    name: 'Study',
    component: () => import('@/views/Study.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/studies/:id',
    name: 'VisualizeStudyTree',
    component: () => import('@/views/VisualizeStudyTree.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/audit-trail',
    name: 'AuditTrial',
    component: () => import('@/views/AuditTrial.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/audit-users',
    name: 'AuditUsers',
    component: () => import('@/views/AuditUsers.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/inbox',
    name: 'Inbox',
    component: () => import('@/views/Inbox.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/library',
    name: 'Library',
    component: () => import('@/views/Library.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/library/edit/:id',
    name: 'EditData',
    component: () => import('@/views/EditData.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/library/upload-files',
    name: 'UploadFiles',
    component: () => import('@/views/UploadFiles.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/library/preclassification',
    name: 'Preclassification',
    component: () => import('@/views/Preclassification.vue'),
    beforeEnter: requireCookie,
  },
  {
    path: '/milestones',
    name: 'milestones',
    component: () => import('@/views/Milestones.vue'),
    beforeEnter: requireCookie,
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
